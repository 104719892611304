exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adaptacje-js": () => import("./../../../src/pages/adaptacje.js" /* webpackChunkName: "component---src-pages-adaptacje-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasi-partnerzy-js": () => import("./../../../src/pages/nasi-partnerzy.js" /* webpackChunkName: "component---src-pages-nasi-partnerzy-js" */),
  "component---src-pages-projekty-gotowe-js": () => import("./../../../src/pages/projekty-gotowe.js" /* webpackChunkName: "component---src-pages-projekty-gotowe-js" */),
  "component---src-pages-projekty-indywidualne-js": () => import("./../../../src/pages/projekty-indywidualne.js" /* webpackChunkName: "component---src-pages-projekty-indywidualne-js" */)
}

